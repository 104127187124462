import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from '../../shared/_services/api.service';
import { DataService } from '../../shared/_services/data.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  host: { '[class]': 'componentClass' }
})
export class LegalComponent implements OnInit, OnDestroy {
  paramSubscription: Subscription;

  componentClass: string;
  slug: string = 'personvern';

  data: any;
  acf: any = [];
  blocks: any = [];
  modules: object;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _apiService: ApiService,
    ) {}

  ngOnInit() {
    this.paramSubscription = this._apiService.getLegal('/pages', {
      params: {
        slug: this.slug
      }
    })
    .subscribe((res) => {
      if ( res && Object.keys(res).length > 0 )
      {
        this._dataService.setData(res)
        this.data = res;

        if ( res[0]['acf']['modules'] ) {
          this.modules = res[0]['acf']['modules'];
        }
      }
      else {
        return 404;
      }
    })

  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LegalComponent } from './legal/legal.component';
// import { BlocksModule } from '../shared/blocks/blocks.module';

@NgModule({
  declarations: [
    HomeComponent,
    LegalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    // BlocksModule
  ]
})
export class PagesModule { }

<ul id="menu" class="navbar-nav override">
  <li class="navbar-item override" (click)="onNavLinkClicked($event)">
    <a [routerLink]="['/internasjonale-operasjoner']" [routerLinkActive]="['active']">Internasjonale operasjoner</a>
  </li>

  <li class="navbar-item override" (click)="onNavLinkClicked($event)">
    <a [routerLink]="['/familie-og-barn']" [routerLinkActive]="['active']">Familie og barn</a>
  </li>

  <li class="navbar-item override" (click)="onNavLinkClicked($event)">
    <a [routerLink]="['/vanlige-helseutfordringer']" [routerLinkActive]="['active']">Vanlige helseutfordringer</a>
  </li>

  <li class="navbar-item override" (click)="onNavLinkClicked($event)">
    <a [routerLink]="['/ressurser-for-deg-som-skal-hjelpe']" [routerLinkActive]="['active']">Ressurser for deg som skal hjelpe</a>
  </li>

  <li class="navbar-item override" (click)="onNavLinkClicked($event)">
    <a [routerLink]="['/kommunalt-veteranarbeid']" [routerLinkActive]="['active']">Kommunalt veteranarbeid</a>
  </li>

  <li class="navbar-item override" (click)="onNavLinkClicked($event)">
    <a [routerLink]="['/om-nettstedet']" [routerLinkActive]="['active']">Om nettstedet</a>
  </li>
</ul>

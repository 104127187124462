import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  @HostBinding('class.drawer-open')
  isDrawerOpen: boolean = false;

  constructor(
    private router: Router) {}

  toggleDrawer(isDrawerOpen: boolean) {
    this.isDrawerOpen = isDrawerOpen;
  }

  toContent() {
    this.router.navigate([], {fragment: 'main'})
    console.log('scrolled?')
  }


}

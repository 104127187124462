import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { DeviceDetectorModule } from 'ngx-device-detector';


import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';


export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: 'RVTS Øst',
    defaults: {
      'og:type': 'website',
      'og:locale': 'nb_NO',
    }
  });
}

@NgModule({
  declarations: [
    FooterComponent,
    NotFoundComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    HeadroomModule,
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: (metaFactory)
    }),
  ],
  exports: [
    FooterComponent,
  ],
})
export class CoreModule {}

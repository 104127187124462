<div class="container" *ngIf="postData">
  <div class="columns is-vcentered is-gapless" itemscope itemtype="https://schema.org/Article">
    <div class="column is-6 is-content">
      <div class="content-wrapper" *ngIf="postData[0]">
        <h1 class="title is-spaced is-3">{{postData[0].title.rendered}}</h1>
        <div [innerHTML]="postData[0].content.rendered | safe:'html'" class="content is-ingress" itemprop="text"
          *ngIf="postData[0].content.rendered; else default"></div>

        <ng-template #default>
          <div class="content is-ingress" itemprop="text">
            <p>Her var det tomt ..</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetaService } from '@ngx-meta/core';

import { environment } from '../../../environments/environment';
import { ApiService } from '../_services/api.service';
import { DataService } from '../_services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  host: { '[class]': 'componentClass' }
})
export class PageComponent implements OnInit, OnDestroy {
  componentClass: string;

  paramSubscription: Subscription;
  browser: boolean;
  data: object;
  blocks: object;
  slug: string;
  imageService: any;
  categoryId: any;


  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private readonly _meta: MetaService,
    private _dataService: DataService,
    private _apiService: ApiService,
  ) {}
  
  ngOnInit() {
    this.paramSubscription = this._route.paramMap.subscribe(
      params => {
        if (params.get('slug') !== 'undefined') {
          this.slug = params.get('slug');
          this._apiService.getData(environment.domain +'/api/wp/v2/pages', {
            params: {
              slug: this.slug
            }
          })
          .subscribe((res) => {
            if (res && Object.keys(res).length > 0) {
              this.componentClass = 'is-' +res[0]['type'] +' ' +res[0]['slug'];
              this._dataService.setData(res)
              this.data = res;
              
              if ( res[0]['acf'] && res[0]['acf']['blocks'] ) {
                this.blocks = res[0]['acf']['blocks'];
              }

              this._meta.setTitle(res[0].title.rendered); 

              if ( res[0].better_featured_image ) {
                this.imageService = res[0].better_featured_image.media_details.sizes;
                if ( this.imageService ) {
                  if ( this.imageService.featured_desktop ) {
                    this._meta.setTag('og:image', environment.domain +this.imageService.featured_desktop.source_url);
                    this._meta.setTag('og:image:width',  this.imageService.featured_desktop.width);
                    this._meta.setTag('og:image:height', this.imageService.featured_desktop.height);
                  
                  } else {
                    this._meta.setTag('og:image', environment.domain +res[0].better_featured_image.source_url);
                    this._meta.setTag('og:image:width',  res[0].better_featured_image.width);
                    this._meta.setTag('og:image:height', res[0].better_featured_image.height);
                  }
                }
              }

              if ( res[0].excerpt ) {
                this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
              }

              // this._meta.setTag('og:type', 'article');
              // this._meta.setTag('article:published_time', res[0].date);
              // this._meta.setTag('article:modified_time', res[0].modified);

            }
            else {
              return 404;
            }
          })
        }
        else {
          return 404;
        }
      }
    )
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.paramSubscription.unsubscribe();
    this._dataService.clearData()
  }

}


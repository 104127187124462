<section class="section" *ngIf="data">
  <div class="container is-widescreen">
    <div class="columns is-vcentered">
      <div class="column is-8 is-content">
        <header class="section-header">
          <h1 [innerHTML]="data[0].title.rendered | safe:'html'" class="title is-4" itemprop="headline"></h1>
        </header>
        <div [innerHTML]="data[0].excerpt.rendered | safe:'html'" class="content is-ingress" itemprop="text"></div>
        <!-- <div class="content-wrapper">

        </div> -->
      </div>
    </div>
  </div>
</section>


<ng-container [ngSwitch]="block?.acf_fc_layout" *ngFor="let block of blocks">

  <ng-template [ngSwitchCase]="'featured'">
    <app-featured [postData]=block.featured.ID></app-featured>
  </ng-template>

  <ng-template [ngSwitchCase]="'articles'">
    <app-categories itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting" [categoryId]="block.category" *ngIf="block.category"></app-categories>
  </ng-template>

  <ng-template [ngSwitchCase]="'image'">
    <img src="{{block.image.sizes?.content_large}}" alt="{{block.image?.alt_text}}" />
  </ng-template>

  <ng-template ngSwitchDefault>

    <ng-container [ngSwitch]="block?.acf_fc_layout">
      <ng-template [ngSwitchCase]="'wysiwyg'">
        <section class="section" [ngClass]="
        [
          block.acf_fc_layout ? 'is-' +block.acf_fc_layout : '',
          block.columns ? 'is-grid is-' +block.columns : '',
          block.color ? block.color : '',
          block.margin ? block.margin : '',
          block.padding ? block.padding : ''
        ]">

        <ng-container [ngSwitch]="block?.columns">

          <ng-template [ngSwitchCase]="'columns'">
            <div class="columns is-vcentered is-gapless" [ngClass]="
            [
              block?.order ? block.order : ''
            ]">
              <div class="column is-content">
                <div class="content-wrapper">
                  <div [innerHTML]="block.wysiwyg | safe:'html'" [ngClass]="['content']" itemprop="text"></div>
                </div>
              </div>
              <div class="column">
                <ng-container [ngSwitch]="block?.asset_type">
                  <ng-template [ngSwitchCase]="'image'">
                    <figure class="image is-3by2" *ngIf="block?.image" itemscope itemtype="https://schema.org/ImageObject">
                      <img src="{{block?.image.sizes?.content_medium}}" alt="{{block.image?.caption}}" itemprop="contentUrl" />
                    </figure>
                  </ng-template>

                  <ng-template [ngSwitchCase]="'video'">
                    <figure [innerHTML]="block?.video | safe:'html'" [ngClass]="['image is-16by9']" itemscope itemtype="https://schema.org/VideoObject"></figure>
                  </ng-template>

                </ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template ngSwitchDefault>
            <div [ngClass]="
            [
              'container',
              block.width ? block.width : ''
            ]">
              <div [innerHTML]="block.wysiwyg | safe:'html'" [ngClass]="['content']" itemprop="text"></div>
            </div>
          </ng-template>

        </ng-container>

        </section>
      </ng-template>

      <ng-template [ngSwitchCase]="'image'">
        <img src="{{block.image.sizes?.content_medium}}" alt="{{block.image?.alt_text}}" />
      </ng-template>
    </ng-container>

  </ng-template>

</ng-container>




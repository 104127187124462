/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./hero.component";
import * as i3 from "@ngx-meta/core";
import * as i4 from "../_services/data.service";
import * as i5 from "ngx-device-detector";
var styles_HeroComponent = [];
var RenderType_HeroComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeroComponent, data: {} });
export { RenderType_HeroComponent as RenderType_HeroComponent };
function View_HeroComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "copyrightNotice"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u00A9 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.copyrightImage; _ck(_v, 1, 0, currVal_0); }); }
export function View_HeroComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "hero-background"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeroComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.copyrightImage; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.featuredImage) + ")"); _ck(_v, 0, 0, currVal_0); }); }
export function View_HeroComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-hero", [], [[8, "className", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onresize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HeroComponent_0, RenderType_HeroComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeroComponent, [i0.PLATFORM_ID, i0.Renderer2, i3.MetaService, i4.DataService, i5.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var HeroComponentNgFactory = i0.ɵccf("app-hero", i2.HeroComponent, View_HeroComponent_Host_0, {}, {}, []);
export { HeroComponentNgFactory as HeroComponentNgFactory };

<app-ingress [postData]="data" itemscope itemtype="https://schema.org/Article"></app-ingress>
<ng-container [ngSwitch]="block?.acf_fc_layout" *ngFor="let block of blocks">

  <ng-template [ngSwitchCase]="'pages'">
    <app-pages [postData]="block?.pageData" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting"></app-pages>
  </ng-template>

  <ng-template [ngSwitchCase]="'featured'">
    <app-featured [postData]="block.featured.ID" itemscope itemprop="blogPost" itemtype="https://schema.org/BlogPosting"></app-featured>
  </ng-template>



  <ng-template [ngSwitchCase]="'image'">
    <img src="{{block.image.sizes.content_medium}}" alt="{{block.image?.alt_text}}" />
  </ng-template>

  <ng-template ngSwitchDefault>

    <ng-container [ngSwitch]="block?.acf_fc_layout">
      <ng-template [ngSwitchCase]="'wysiwyg'">
        <section class="section" [ngClass]="
        [
          block.acf_fc_layout ? 'is-' +block.acf_fc_layout : '',
          block.columns ? 'is-grid is-' +block.columns : '',
          block.color ? block.color : '',
          block.margin ? block.margin : '',
          block.padding ? block.padding : ''
        ]">

        <ng-container [ngSwitch]="block?.columns">

          <ng-template [ngSwitchCase]="'columns'">
            <div class="columns is-vcentered is-gapless" [ngClass]="
            [
              block?.order ? block.order : ''
            ]">
              <div class="column is-content">
                <div class="content-wrapper">
                  <div [innerHTML]="block.wysiwyg | safe:'html'" [ngClass]="['content']" itemprop="text"></div>
                </div>
              </div>
              <div class="column">
                <ng-container [ngSwitch]="block?.asset_type">
                  <ng-template [ngSwitchCase]="'image'">
                    <figure class="image is-3by2" *ngIf="block?.image">
                      <img src="{{block?.image.sizes?.content_medium}}" alt="{{block.image?.caption}}" />
                    </figure>
                  </ng-template>

                  <ng-template [ngSwitchCase]="'video'">
                    <figure [innerHTML]="block?.video | safe:'html'" [ngClass]="['image is-3by2']" itemprop="text"></figure>
                  </ng-template>

                </ng-container>
              </div>
            </div>
          </ng-template>

          <ng-template ngSwitchDefault>
            <div [ngClass]="
            [
              'container',
              block.width ? block.width : ''
            ]">
              <div [innerHTML]="block.wysiwyg | safe:'html'" [ngClass]="['content']" itemprop="text"></div>
            </div>
          </ng-template>

        </ng-container>

        </section>
      </ng-template>

      <ng-template [ngSwitchCase]="'image'">
        <img src="{{block.image.sizes?.content_medium}}" alt="{{block.image?.alt_text}}" />
      </ng-template>
    </ng-container>

  </ng-template>

</ng-container>

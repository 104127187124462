import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ingress',
  templateUrl: './ingress.component.html',
  host: { '[class]': 'componentClass' }
})
export class IngressComponent implements OnInit {
  @Input() postData: object;
  componentClass = 'section has-padding--none is-featured is-ingress';

  ngOnInit() {

  }

}

import { Component, HostListener, OnInit, Output, EventEmitter, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styles: []
})
export class NavBarComponent implements OnInit {
  navElement: HTMLElement;

  isDrawerOpen: boolean;

  @Output()
  drawerToggleEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.navElement = null;
    this.isDrawerOpen = false;
  }

  ngAfterViewInit() {
    this.navElement = <HTMLElement> document.getElementById("navbar");
  }

  @HostListener("window:scroll", ["$event"])
  onScroll($event: Event) {
    // let scrollFactor = 200;
    // let opacity = (window.pageYOffset / scrollFactor);
    // opacity = opacity < 1 ? opacity : 1;

    // if (opacity <= 1) {
    //   this.navElement.style.backgroundColor = "rgba(255, 255, 255, " + opacity + ")";
    // }

    // if (window.pageYOffset / scrollFactor > 1) {
    //   this.navElement.classList.add("navbar-shadow");
    // } else {
    //   this.navElement.classList.remove("navbar-shadow");
    // }
  }

  toggleNavDrawer(isDrawerOpen: boolean) {
    this.isDrawerOpen = isDrawerOpen;
    this.drawerToggleEmitter.emit(this.isDrawerOpen);

    if (this.isDrawerOpen) {
      this.renderer.addClass(this.document.body, 'menu-open');
    } else {
      this.renderer.removeClass(this.document.body, 'menu-open');
    }
  }

}

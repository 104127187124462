<div *ngIf="data" [ngClass]="[
  (data[0].acf?.featuredPreview === 'app') ? 'container' : ''
]">
  <div class="columns is-vcentered is-gapless">
    <div class="column is-6 is-content">
      <div class="content-wrapper">

        <header class="header">
          <h1 [innerHTML]="data[0].acf.title ? data[0].acf.title : data[0].title.rendered  | safe:'html'" class="title is-spaced is-3" itemprop="headline"></h1>
        </header>

        <div [innerHTML]="data[0].acf.wysiwyg | safe:'html'" [ngClass]="['content -ingress']" itemprop="text"></div>

        <div class="download-badge-wrapper" *ngIf="data[0].acf.links">

          <ng-container [ngSwitch]="data[0].acf?.linkStyle">

            <ng-template [ngSwitchCase]="'button'">
              <div class="buttons">
                <ng-container *ngFor="let link of data[0].acf.links" [ngSwitch]="link?.type">

                  <ng-template [ngSwitchCase]="'internal'">
                    <a [routerLink]="link.object.post_name | postType:link.object.post_type" [ngClass]="
                      [
                        'button',
                        data[0].acf.btnColor ? data[0].acf.btnColor : '',
                        data[0].acf.btnStyle ? data[0].acf.btnStyle : ''
                      ]">{{link.text}}</a>
                  </ng-template>

                  <ng-template [ngSwitchCase]="'external'">
                    <a href="{{link.url}}" target="_blank" [ngClass]="
                      [
                        'button',
                        data[0].acf.btnColor ? data[0].acf.btnColor : '',
                        data[0].acf.btnStyle ? data[0].acf.btnStyle : ''
                      ]">{{link.text}}</a>
                  </ng-template>

                </ng-container>

              </div>

            </ng-template>

            <ng-template [ngSwitchCase]="'list'">
              <ul class="">
                <li *ngFor="let link of data[0].acf.links" [ngSwitch]="link?.type">

                  <ng-template [ngSwitchCase]="'internal'">
                    <a [routerLink]="link.object.post_name | postType:link.object.post_type" [ngClass]="
                      []">{{link.text}}</a>
                  </ng-template>

                  <ng-template [ngSwitchCase]="'external'">
                    <a href="{{link.url}}" target="_blank" [ngClass]="
                      []">{{link.text}}</a>
                  </ng-template>

                </li>
              </ul>
            </ng-template>

          </ng-container>

        </div>

        <!-- <div [innerHTML]="data[0].acf.disclaimer | safe:'html'" [ngClass]="['content is-small disclaimer']" itemprop="text" *ngIf="data[0].acf.disclaimer"></div> -->
      </div>
    </div>

    <ng-container [ngSwitch]="data[0].acf?.featuredPreview">
      <ng-template [ngSwitchCase]="'app'">
        <div class="column is-4 is-image" *ngIf="data && data[0]?.better_featured_image">
          <div class="device-wrapper">
            <div class="device">
              <figure class="device-screen" itemscope itemtype="https://schema.org/ImageObject">
                <img src="{{data[0].better_featured_image.media_details.sizes?.hero_mobile.source_url}}" alt="{{data[0]?.better_featured_image.caption}}" itemprop="contentUrl" />
              </figure>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'promo'">
        <div class="column is-6">
          <figure class="image is-3by2" *ngIf="data && data[0].better_featured_image" itemscope itemtype="https://schema.org/ImageObject">
            <img src="{{data[0].better_featured_image.media_details.sizes?.content_medium.source_url}}" alt="{{data[0]?.better_featured_image.caption}}" itemprop="contentUrl" />
            <div class="copyrightNotice" *ngIf="data[0].better_featured_image.media_details.image_meta.credit || data[0].better_featured_image.media_details.image_meta.copyright">&copy; {{data[0].better_featured_image.media_details.image_meta.credit ? data[0].better_featured_image.media_details.image_meta.credit : data[0].better_featured_image.media_details.image_meta.copyright }}</div>
          </figure>
        </div>
      </ng-template>
    </ng-container>

  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../_pipes/safe.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./pages.component";
import * as i6 from "../_services/api.service";
var styles_PagesComponent = [];
var RenderType_PagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PagesComponent, data: {} });
export { RenderType_PagesComponent as RenderType_PagesComponent };
function View_PagesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "copyrightNotice"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\u00A9 ", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.better_featured_image.media_details.image_meta.credit ? _v.parent.context.$implicit.better_featured_image.media_details.image_meta.credit : _v.parent.context.$implicit.better_featured_image.media_details.image_meta.copyright); _ck(_v, 1, 0, currVal_0); }); }
function View_PagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "section", [["class", "section is-grid has-background-grey-lighter"], ["itemprop", "blogPost"], ["itemscope", ""], ["itemtype", "https://schema.org/BlogPosting"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "columns is-vcentered is-gapless"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "figure", [["class", "image is-3by2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PagesComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 11, "div", [["class", "column is-6 is-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 10, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "header", [["class", "section-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "h1", [["class", "title is-4"], ["itemprop", "headline"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(11, 2), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "content has-decoration-top"], ["itemprop", "text"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(13, 2), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [["class", "download-badge-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "a", [["class", "button is-dark"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(17, 1), (_l()(), i0.ɵted(-1, null, ["Les mer"]))], function (_ck, _v) { var currVal_2 = (_v.context.$implicit.better_featured_image.media_details.image_meta.credit || _v.context.$implicit.better_featured_image.media_details.image_meta.copyright); _ck(_v, 6, 0, currVal_2); var currVal_7 = _ck(_v, 17, 0, ("/" + _v.context.$implicit.slug)); _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", ((_v.context.$implicit.better_featured_image.media_details.sizes == null) ? null : _v.context.$implicit.better_featured_image.media_details.sizes.content_medium.source_url), ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", ((_v.context.$implicit.better_featured_image == null) ? null : _v.context.$implicit.better_featured_image.alt_text), ""); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), _v.context.$implicit.title.rendered, "html")); _ck(_v, 10, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v.parent, 0), _v.context.$implicit.excerpt.rendered, "html")); _ck(_v, 12, 0, currVal_4); var currVal_5 = i0.ɵnov(_v, 16).target; var currVal_6 = i0.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_5, currVal_6); }); }
export function View_PagesComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.SafePipe, [i4.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PagesComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pages", [], [[8, "className", 0]], null, null, View_PagesComponent_0, RenderType_PagesComponent)), i0.ɵdid(1, 245760, null, 0, i5.PagesComponent, [i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var PagesComponentNgFactory = i0.ɵccf("app-pages", i5.PagesComponent, View_PagesComponent_Host_0, { postData: "postData" }, {}, []);
export { PagesComponentNgFactory as PagesComponentNgFactory };

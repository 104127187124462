import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeroComponent } from './hero/hero.component';
import { PageComponent } from './page/page.component';
import { PostComponent } from './post/post.component';

import { SafePipe } from './_pipes/safe.pipe';
import { CategoryPipe } from './_pipes/category.pipe';
import { PostTypePipe } from './_pipes/post-type.pipe';

import { FeaturedComponent } from './featured/featured.component';
import { CategoriesComponent } from './categories/categories.component';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { IngressComponent } from './ingress/ingress.component';
import { PagesComponent } from './pages/pages.component';


@NgModule({
  declarations: [
    HeroComponent,
    PageComponent,
    PostComponent,
    SafePipe,
    CategoryPipe,
    PostTypePipe,
    FeaturedComponent,
    CategoriesComponent,
    CollaboratorsComponent,
    IngressComponent,
    PagesComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    HeroComponent,
    PageComponent,
    PostComponent,
    SafePipe,
    CategoryPipe,
    PostTypePipe,
    FeaturedComponent,
    CategoriesComponent,
    CollaboratorsComponent,
    IngressComponent,
    PagesComponent
  ]
})
export class SharedModule { }

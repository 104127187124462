<section class="section is-grid has-background-grey-lighter" *ngFor="let item of data" itemscope itemprop="blogPost"
itemtype="https://schema.org/BlogPosting"> 
  <div class="columns is-vcentered is-gapless">
    
    <div class="column">
      <figure class="image is-3by2">
        <img src="{{item.better_featured_image.media_details.sizes?.content_medium.source_url}}" alt="{{item.better_featured_image?.alt_text}}" />
        <div class="copyrightNotice" *ngIf="item.better_featured_image.media_details.image_meta.credit || item.better_featured_image.media_details.image_meta.copyright">&copy; {{item.better_featured_image.media_details.image_meta.credit ? item.better_featured_image.media_details.image_meta.credit : item.better_featured_image.media_details.image_meta.copyright }}</div>
      </figure>
    </div>
    
    <div class="column is-6 is-content">
      <div class="content-wrapper">
        <header class="section-header">
          <h1 [innerHTML]="item.title.rendered | safe:'html'" class="title is-4" itemprop="headline"></h1>
        </header>
        
        <div [innerHTML]="item.excerpt.rendered | safe:'html'" class="content has-decoration-top" itemprop="text"></div>
        
        <div class="download-badge-wrapper">
          <a class="button is-dark" [routerLink]="['/' + item.slug]">Les mer</a>
        </div>

      </div>
    </div>
  </div>
</section>
import { Component, OnInit, OnDestroy, HostListener, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { MetaService } from '@ngx-meta/core';
import { environment } from '../../../environments/environment';

import { DataService } from '../_services/data.service'; 

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  host: { '[class]': 'componentClass' }
})
export class HeroComponent implements OnInit, OnDestroy {
  componentClass: string = 'hero';
  subscription: Subscription;

  data: object;

  imageService: Array<{sizes: any, meta: any}> = [];
  socialImage: Array<{width: string, height: string, sourceUrl: string}> = [];

  featuredImage: string = '';
  copyrightImage: string = '';

  deviceInfo: any = null;
  mobileDevice: boolean = false;
  tabletDevice: boolean = false;
  desktopDevice: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private renderer: Renderer2,
    private readonly _meta: MetaService,
    private _dataService: DataService,
    private _deviceService: DeviceDetectorService,
  ) {}
  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.deviceFunction();
  }

  ngOnInit() {
    this.deviceFunction();

    this.subscription = this._dataService._apiData$
    .subscribe((res) => {

      this.imageService = [];
      this.socialImage = [];

      this.featuredImage = '';
      this.copyrightImage = '';

      if (res) {
        this.data = res;

        if ( res[0].better_featured_image ) {
          this.socialImage.push({ 
            width: res[0].better_featured_image.media_details.sizes.share.width, 
            height: res[0].better_featured_image.media_details.sizes.share.height, 
            sourceUrl: res[0].better_featured_image.media_details.sizes.share.source_url 
          });

          this.imageService.push({
            sizes: res[0].better_featured_image.media_details.sizes,
            meta: res[0].better_featured_image.media_details.image_meta
          });

          if ( this.mobileDevice ) {
            if ( this.imageService[0]['sizes'].hero_mobile ) {
              this.featuredImage = '' +this.imageService[0]['sizes'].hero_mobile.source_url;
            }
          } 
          else {
            if ( this.imageService[0]['sizes'].hero ) {
              this.featuredImage = this.imageService[0]['sizes'].hero.source_url;
            } 
            else if ( this.imageService[0]['sizes'].content_large ) {
              this.featuredImage = this.imageService[0]['sizes'].content_large.source_url;
            }
            else {
              this.featuredImage = this.imageService[0]['sizes'].content.source_url;;
            }
          }

          if ( res[0].better_featured_image.media_details.image_meta.credit ) {
            this.copyrightImage = res[0].better_featured_image.media_details.image_meta.credit;
          }
          else if ( res[0].better_featured_image.media_details.image_meta.copyright ) {
            this.copyrightImage = res[0].better_featured_image.media_details.image_meta.copyright;
          }

          if ( this.socialImage ) {
            this._meta.setTag('og:image', environment.domain +this.socialImage[0]['sourceUrl']);
            this._meta.setTag('og:image:width',  this.socialImage[0]['width']);
            this._meta.setTag('og:image:height', this.socialImage[0]['height']);
          }

          this._meta.setTitle(res[0].title.rendered);
          this._meta.setTag('og:type', 'website');
  
          if ( res[0].excerpt ) {
            this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
          }

          if ( this.imageService[0]['meta'].copyright ) {
            this.copyrightImage = this.imageService[0]['meta'].copyright;
          }
          else if ( this.imageService[0]['meta'].credit ) {
            this.copyrightImage = this.imageService[0]['meta'].credit;
          }


        // if ( res[0].better_featured_image) {
        //   this.imageService = res[0].better_featured_image.media_details.sizes;

        //   if ( this.imageService.hero ) {
        //     this.featuredImage = this.imageService.hero.source_url;
        //   } 
        //   else if ( this.imageService.content_large ) {
        //     this.featuredImage = this.imageService.content_large.source_url;
        //   }
        //   else {
        //     this.featuredImage = this.imageService.content.source_url;
        //   }

        //   if ( this.mobileDevice ) {
        //     if ( this.imageService.hero_mobile ) {
        //       this.featuredImage = '' +this.imageService.hero_mobile.source_url;
        //     }
        //   }

        //   if ( res[0].better_featured_image.media_details.image_meta.credit ) {
        //     this.copyrightImage = res[0].better_featured_image.media_details.image_meta.credit;
        //   }
        //   else if ( res[0].better_featured_image.media_details.image_meta.copyright ) {
        //     this.copyrightImage = res[0].better_featured_image.media_details.image_meta.copyright;
        //   }

          // if ( this.imageService.share ) {
          //   this._meta.setTag('og:image', environment.domain +this.imageService.share.source_url);
          //   this._meta.setTag('og:image:width', this.imageService.share.width);
          //   this._meta.setTag('og:image:height', this.imageService.share.height);
          // }
        }
      }
    });
  }

  ngOnDestroy() {
    console.log('Component will be destroyed')
    this.subscription.unsubscribe();
  }

  deviceFunction(): void {
    this.deviceInfo = this._deviceService.getDeviceInfo();
    this.mobileDevice = this._deviceService.isMobile();
    this.tabletDevice = this._deviceService.isTablet();
    this.desktopDevice = this._deviceService.isDesktop();
  }

}

import { Component, OnInit } from '@angular/core';
import { version } from '../../../../package.json'; 

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  host: { '[class]': 'componentClass' }
})
export class FooterComponent implements OnInit {
  componentClass = 'app-footer';
  public version: string = version;


  today: number = Date.now();
  companyName = 'RVTS Øst'
  companyExtended = 'Regionalt ressurssenter om vold, traumatisk stress og selvmordsforebygging.'


  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';
import { MetaService } from '@ngx-meta/core';

import { ApiService } from '../../shared/_services/api.service';
import { DataService } from '../../shared/_services/data.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  host: { '[class]': 'componentClass' }
})
export class HomeComponent implements OnInit, OnDestroy {
  componentClass = 'is-home';

  subscription: Subscription;
  data: object;
  blocks: object;

  browser: boolean;
  postData: any;


  constructor(
    private readonly _meta: MetaService,
    private _dataService: DataService,
    private _apiService: ApiService,
    @Inject(PLATFORM_ID) private platform: Object
  ) {}

  ngOnInit() {
    this._dataService.clearData()
    this.subscription = this._apiService.getData(environment.domain +'/api/wp/v2/pages', {
      params: {
        slug: 'forside',
      }
    })
    .subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this._dataService.setData(res)
        this.data = res;

        if ( res[0]['acf'] && res[0]['acf']['blocks'] ) {
          this.blocks = res[0]['acf']['blocks'];
        }

        this._meta.setTitle(res[0].title.rendered.replace(/&#8211;/g, '-'));
        this._meta.setTag('og:type', 'website');

        if ( res[0].excerpt ) {
          this._meta.setTag('og:description', res[0].excerpt.rendered.replace(/<[^>]*>/g, ''));
        }
      }
    })

    if (isPlatformBrowser(this.platform)) {
      this.browser = true;
    }
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.subscription.unsubscribe();
    this._dataService.clearData()
  }
}

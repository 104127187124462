/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./categories.component";
import * as i3 from "../_services/api.service";
var styles_CategoriesComponent = [];
var RenderType_CategoriesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CategoriesComponent, data: {} });
export { RenderType_CategoriesComponent as RenderType_CategoriesComponent };
function View_CategoriesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "column is-3"], ["itemprop", "blogPost"], ["itemscope", ""], ["itemtype", "https://schema.org/BlogPosting"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "card-image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "figure", [["class", "image is-3by2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", ((_v.context.$implicit.better_featured_image.media_details.sizes == null) ? null : _v.context.$implicit.better_featured_image.media_details.sizes.content_small.source_url), ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", ((_v.context.$implicit.better_featured_image == null) ? null : _v.context.$implicit.better_featured_image.alt_text), ""); _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_CategoriesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "columns is-multiline"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CategoriesComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CategoriesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-categories", [], [[8, "className", 0]], null, null, View_CategoriesComponent_0, RenderType_CategoriesComponent)), i0.ɵdid(1, 245760, null, 0, i2.CategoriesComponent, [i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var CategoriesComponentNgFactory = i0.ɵccf("app-categories", i2.CategoriesComponent, View_CategoriesComponent_Host_0, { categoryId: "categoryId" }, {}, []);
export { CategoriesComponentNgFactory as CategoriesComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./core/nav-bar/nav-bar.component.ngfactory";
import * as i2 from "./core/nav-bar/nav-bar.component";
import * as i3 from "@angular/common";
import * as i4 from "./shared/hero/hero.component.ngfactory";
import * as i5 from "./shared/hero/hero.component";
import * as i6 from "@ngx-meta/core";
import * as i7 from "./shared/_services/data.service";
import * as i8 from "ngx-device-detector";
import * as i9 from "@angular/router";
import * as i10 from "./core/footer/footer.component.ngfactory";
import * as i11 from "./core/footer/footer.component";
import * as i12 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "link-content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Hopp til innhold"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "nav-bar", [], null, [[null, "drawerToggleEmitter"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("drawerToggleEmitter" === en)) {
        var pd_1 = (_co.toggleDrawer($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_NavBarComponent_0, i1.RenderType_NavBarComponent)), i0.ɵdid(3, 4308992, null, 0, i2.NavBarComponent, [i3.DOCUMENT, i0.Renderer2], null, { drawerToggleEmitter: "drawerToggleEmitter" }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-hero", [["itemprop", "primaryImageOfPage"], ["itemscope", ""]], [[8, "className", 0]], [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onresize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_HeroComponent_0, i4.RenderType_HeroComponent)), i0.ɵdid(5, 245760, null, 0, i5.HeroComponent, [i0.PLATFORM_ID, i0.Renderer2, i6.MetaService, i7.DataService, i8.DeviceDetectorService], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "main", [["class", "wrap"], ["id", "main"], ["itemprop", "mainContentOfPage"], ["itemscope", ""], ["itemtype", "http://schema.org/Blog"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(8, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "app-footer", [["itemscope", ""], ["itemtype", "https://schema.org/WPFooter"], ["role", "contentinfo"]], [[8, "className", 0]], null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i0.ɵdid(10, 114688, null, 0, i11.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).componentClass; _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 10).componentClass; _ck(_v, 9, 0, currVal_1); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], [[2, "drawer-open", null]], null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i12.AppComponent, [i9.Router], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isDrawerOpen; _ck(_v, 0, 0, currVal_0); }); }
var AppComponentNgFactory = i0.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../_pipes/safe.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./ingress.component";
var styles_IngressComponent = [];
var RenderType_IngressComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IngressComponent, data: {} });
export { RenderType_IngressComponent as RenderType_IngressComponent };
function View_IngressComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "content is-ingress"], ["itemprop", "text"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent.parent, 0), _co.postData[0].content.rendered, "html")); _ck(_v, 0, 0, currVal_0); }); }
function View_IngressComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "content is-ingress"], ["itemprop", "text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Her var det tomt .."]))], null, null); }
function View_IngressComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "title is-spaced is-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IngressComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["default", 2]], null, 0, null, View_IngressComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.postData[0].content.rendered; var currVal_2 = i0.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postData[0].title.rendered; _ck(_v, 2, 0, currVal_0); }); }
function View_IngressComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "columns is-vcentered is-gapless"], ["itemscope", ""], ["itemtype", "https://schema.org/Article"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "column is-6 is-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IngressComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postData[0]; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_IngressComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IngressComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_IngressComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ingress", [], [[8, "className", 0]], null, null, View_IngressComponent_0, RenderType_IngressComponent)), i0.ɵdid(1, 114688, null, 0, i4.IngressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).componentClass; _ck(_v, 0, 0, currVal_0); }); }
var IngressComponentNgFactory = i0.ɵccf("app-ingress", i4.IngressComponent, View_IngressComponent_Host_0, { postData: "postData" }, {}, []);
export { IngressComponentNgFactory as IngressComponentNgFactory };

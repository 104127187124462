import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Subscription } from 'rxjs'; 
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  host: { '[class]': 'componentClass' }
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @Input() categoryId: string;

  componentClass = 'section is-grid is-categories py5';

  subscription: Subscription;
  data: object;

  constructor(
    private _apiService: ApiService,
  ) {}

  ngOnInit() {
    this.subscription = this._apiService.getData(environment.domain +'/api/wp/v2/posts', {
      params: {
        per_page: '100',
        categories: this.categoryId,
        order: 'desc',
      }
    })
    .subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this.data = res;
      }
    })
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.subscription.unsubscribe();
  }
}


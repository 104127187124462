import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './core/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';

import { PageComponent } from './shared/page/page.component';
import { PostComponent } from './shared/post/post.component';
import { LegalComponent } from './pages/legal/legal.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'personvern',
    component: LegalComponent
  },
  {
    path: ':slug',
    component: PageComponent,
  },
  {
    path: 'artikkel/:slug',
    component: PostComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}

import { MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
export function metaFactory() {
    return new MetaStaticLoader({
        pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
        pageTitleSeparator: ' - ',
        applicationName: 'RVTS Øst',
        defaults: {
            'og:type': 'website',
            'og:locale': 'nb_NO',
        }
    });
}
const ɵ0 = (metaFactory);
export class CoreModule {
}
export { ɵ0 };

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postType'
})
export class PostTypePipe implements PipeTransform {

  public transform(value: any, type?: any) {
    console.log(type);
    switch (type) {
      case 'page': return '/' +value;
      case 'post': return '/artikkel/' +value;
      // case 'project': return '/verktoy/' +value;
      // case 'event': return '/kurs/' +value;
      default: return '/';
    } 
  } 
}

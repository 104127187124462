<div class="columns is-multiline">

  <div class="column is-3" *ngFor="let item of data" itemscope itemprop="blogPost"
    itemtype="https://schema.org/BlogPosting">

    <div class="card">
      <div class="card-image">
        <figure class="image is-3by2">
          <img src="{{item.better_featured_image.media_details.sizes?.content_small.source_url}}"
            alt="{{item.better_featured_image?.alt_text}}">
        </figure>
      </div>
    </div>

  </div>

</div>

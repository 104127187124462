import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../_services/api.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  host: { '[class]': 'componentClass' }
})
export class PagesComponent implements OnInit, OnDestroy {
  @Input() postData: Array<string>;

  componentClass = 'is-categories';

  subscription: Subscription;
  data: object;

  constructor(
    private _apiService: ApiService,
  ) {}

  ngOnInit() {
    this.subscription = this._apiService.getData(environment.domain +'/api/wp/v2/pages', {
      params: {
        include: [this.postData],
        order: 'asc',
      }
    })
    .subscribe((res) => {
      if (res && Object.keys(res).length > 0) {
        this.data = res;
      }
    })
  }

  ngOnDestroy() {
    console.log('Component will be destroyed');
    this.subscription.unsubscribe();
  }
}

